@import 'https://static.pw.live/fonts/reddit/font.css';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.menu-btn-indicator {
  top: 2px;
  right: 2px;
  background: red;
}

.animate-pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 0, 0, 0);
  }
}

/* You can add global styles to this file, and also import other style files */
.primary-color {
  color: #5a4bda;
}

.bg-primary {
  background-color: #5a4bda;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

@media print {
  #printContainer > div {
    display: flex;
  }
}

.in-page-toolbar {
  background: #fff;
  // margin: 10px;
  // margin: 10px 0 0 55px;
  box-shadow: 0px 3px 10px #0000001a;
  height: 68px !important;

  h2 {
    font-size: 24px;
    color: #262626;
  }
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

body {
  margin: 0;
}

h1,
h2 {
  font-weight: 700;
  margin: 0 !important;
}

h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin: 0 !important;
}

// p,
// mat-card,
// .mat-button,
// .mat-raised-button,
// .mat-stroked-button,
// .mat-flat-button,
// mat-radio-button,
// span {
// }

/*for video player icons*/

button {
  .vjs-icon-placeholder {
    font-family: VideoJS !important;
  }

  .vjs-icon-play {
    font-family: VideoJS !important;
  }

  .vjs-control-text {
    font-family: inherit !important;
  }

  outline: none !important;
}

.vertical-align {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.plan-modal {
  border-radius: 15px;
}

.purchase-alert {
  .mat-dialog-container {
    padding: 25px;
  }

  height: 120px;
}

.faq-modal-component {
  .mat-dialog-container {
    height: 100vh;
    width: 500px;
    background-color: #fff;
  }
}

.teacher-active-or-not {
  .mat-dialog-container {
    padding: 12px;
    // height: 160px;
    width: 450px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #00000029;
    border-radius: 7px;
    font-size: 2px !important;
  }
}

.main-container {
  margin: 0 auto;
  width: 90%;

  @media (min-width: 1920px) {
    width: 1200px;
  }

  @media (max-width: 768px) {
    width: 678px;
  }

  @media (max-width: 425px) {
    width: 393px;
  }

  @media (max-width: 360px) {
    width: 328px;
  }
}

.custom-dialog-container .mat-dialog-container {
  width: 280px;
  padding: 10px;
  overflow: hidden;

  @media (max-width: 425px) {
    padding: 5px;
  }
}

/* Classes for font-size */

.heading-1 {
  font-size: 40px !important;

  @media (max-width: 992px) {
    font-size: 35px !important;
  }

  @media (max-width: 768px) {
    font-size: 33px !important;
  }

  @media (max-width: 576px) {
    font-size: 35px !important;
  }
}

.heading-2 {
  font-size: 30px !important;

  @media (max-width: 992px) {
    font-size: 27px !important;
  }

  @media (max-width: 768px) {
    font-size: 25px !important;
  }

  @media (max-width: 576px) {
    font-size: 23px !important;
  }
}

.heading-3 {
  font-size: 24px !important;

  @media (max-width: 992px) {
    font-size: 22px !important;
  }

  @media (max-width: 768px) {
    font-size: 19px !important;
  }

  @media (max-width: 576px) {
    font-size: 16px !important;
  }
}

.heading-4 {
  font-size: 20px !important;

  @media (max-width: 992px) {
    font-size: 18px !important;
  }

  @media (max-width: 768px) {
    font-size: 16px !important;
  }

  @media (max-width: 576px) {
    font-size: 15px !important;
  }
}

.heading-5 {
  font-size: 18px !important;

  @media (max-width: 992px) {
    font-size: 16px !important;
  }

  @media (max-width: 768px) {
    font-size: 15px !important;
  }

  @media (max-width: 576px) {
    font-size: 13px !important;
  }

  @media (orientation: landscape) {
    font-size: 13px;
  }
}

.body-1 {
  font-size: 16px !important;

  @media (max-width: 992px) {
    font-size: 14px !important;
  }

  @media (max-width: 768px) {
    font-size: 13px !important;
  }

  @media (max-width: 576px) {
    font-size: 12px !important;
  }
}

.body-2 {
  font-size: 14px !important;

  @media (max-width: 992px) {
    font-size: 13px !important;
  }

  @media (max-width: 768px) {
    font-size: 12px !important;
  }

  @media (max-width: 576px) {
    font-size: 11px !important;
  }
}

.caption-1 {
  font-size: 12px !important;

  @media (max-width: 992px) {
    font-size: 11px !important;
  }

  @media (max-width: 576px) {
    font-size: 10px !important;
  }
}

.caption-2 {
  font-size: 10px !important;

  @media (max-width: 992px) {
    font-size: 9px !important;
  }

  @media (max-width: 576px) {
    font-size: 8px !important;
  }
}

// Classes for All the Font weights
.bold {
  font-weight: 700 !important;
}

.bold-2 {
  font-weight: 400 !important;
}

.bold-1 {
  font-weight: 600 !important;
}

.semibold {
  font-weight: 600 !important;
}

.medium {
  font-weight: 500 !important;
}

.black {
  font-weight: 900 !important;
}

.light {
  font-weight: 300 !important;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.pw-black {
  color: #1b2124;
}

.pw-gray-400 {
  color: #757575;
}

ngx-otp-input {
  .otp-input-class {
    height: 60px !important;
    width: 60px !important;
    background-color: #fff !important;
    font-size: 24px !important;
    font-weight: bold !important;
    color: #000000 !important;
    box-shadow: 0px 3px 10px #182e452e !important;
    outline: none !important;
    border: none !important;
    border-radius: 12px !important;
  }
}

.vertical-align {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.start-test-dialog {
  height: 75vh;
  padding-bottom: 0 !important;

  .mat-dialog-container {
    max-width: 500px;
    overflow: hidden;
    padding: 18px;
    border-radius: 20px;
    overflow: hidden;
  }
}

.batch-attachment-modal {
  .mat-dialog-container {
    border-radius: 15px;
    width: 450px;
    max-height: 90vh;

    @media (max-width: 768px) {
      width: 90vw;
    }
  }
}

.respon-view-more .mat-dialog-container {
  // border-radius: 17px !important;
  padding: 12px !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #594bd9 !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important;
  /*click effect color change*/
  background-color: #5a4bda !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #5a4bda !important;
  /*inner circle color change*/
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5a4bda !important;
  /*outer ring color change*/
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #5a4bda !important;
}

.aspect-video {
  aspect-ratio: 16/9;
}

.is-not-active {
  filter: grayscale(1);
}

input {
  outline: none !important;
}

ngx-otp-input {
  .ngx-otp-input-box.my-super-box-class {
    box-shadow: 0px 3px 10px #182e452e !important;
    border-radius: 12px;
    font-size: 24px !important;
    font-weight: bold !important;
    color: #000000 !important;
    outline: none !important;
    border: none !important;

    .my-super-class {
      width: 50px;
      height: 50px;
      border: none;
      border-radius: 10px;

      @media (max-width: 340px) {
        width: 45px;
        height: 45px;
      }
    }
  }
}

.error {
  .ngx-otp-input-box.my-super-box-class {
    border: 2px solid var(--ion-color-danger);
  }
}

/* Classes for custom scrollbar */

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: #594bdac7;
  border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5a4bda;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 0px;
  box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}

//ngx-otp-input {
//  .otp-input-class {
//    height: 60px !important;
//    width: 60px !important;
//    background-color: #fff !important;
//    font-size: 24px !important;
//    font-weight: bold !important;
//    color: #000000 !important;
//    box-shadow: 0px 3px 10px #182e452e !important;
//    outline: none !important;
//    border: none !important;
//    border-radius: 12px !important;
//  }
//}

/* Classes for dialogs */

.video-sol-modal {
  height: 450px;
  width: 720px;

  @media (max-width: 560px) {
    .mat-dialog-container {
      padding: 6px;
    }

    max-width: 95vw !important;
    height: 350px;
  }
}

.upgrade-to-saarthi-modal {
  .mat-dialog-container {
    height: 397px;
    border-radius: 10px;

    @media (max-width: 540px) {
      padding: 10px;
      width: 95vw;
      height: 300px;
    }
  }
}

.start-test-dialog {
  height: 75vh;
  padding-bottom: 0 !important;

  .mat-dialog-container {
    max-width: 500px;
    overflow: hidden;
    padding: 18px;
    border-radius: 20px;
    overflow: hidden;
  }
}

.batch-attachment-modal {
  .mat-dialog-container {
    border-radius: 15px;
    width: 450px;
    max-height: 90vh;

    @media (max-width: 768px) {
      width: 90vw;
    }
  }
}

.rating_dialog {
  max-width: 94vw !important;
}

.registration_dialog {
  max-width: 90vw !important;
}

.enroll_dialog {
  max-width: 90vw !important;
}

.batch_dialog {
  max-width: 90vw !important;
}

.questions_dialog {
  max-width: 92vw !important;
}

.more_options_dialog {
  max-width: 92vw !important;
  height: 528px;

  .mat-dialog-container {
    padding: 0 !important;
  }
}

.img_dialog {
  max-width: 92vw !important;

  //height: 528px;
  .mat-dialog-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 20px;

    @media (max-width: 576px) {
      padding: 0 !important;
    }
  }
}

.customize-dialog {
  max-width: 92vw !important;
  height: 528px;

  .mat-dialog-container {
    border-radius: 15px;
  }

  @media (min-width: 768px) {
    height: 450px;
  }
}

.report_exercise_question_dialog {
  max-width: 92vw !important;
  height: 498px;
}

.purchase-dialog-modal {
  max-height: 90vh;
  max-width: 300px;

  .mat-dialog-container {
    border-radius: 15px;
  }
}

.update-academic-info {
  max-height: 98vh;

  .mat-dialog-container {
    border-radius: 20px;
    width: 40vw;

    @media (max-width: 768px) {
      width: 90vw;
    }
  }
}

.address-dialog-modal {
  max-height: 90vh;
  width: 40vw;

  @media (max-width: 576px) {
    width: 95vw;
  }

  .mat-dialog-container {
    border-radius: 15px;
  }
}

.add_note_dialog {
  max-width: 92vw !important;

  @media (max-width: 415px) {
    height: 364px;
  }

  @media (max-width: 390px) {
    height: 364px;
  }

  @media (max-width: 376px) {
    height: 356px;
  }

  @media (max-width: 360px) {
    height: 368px;
  }

  @media (max-width: 321px) {
    height: 360px;
  }

  @media (max-width: 281px) {
    height: 375px;
  }

  @media (min-width: 768px) {
    height: 364px;
  }

  @media (min-width: 1024px) {
    height: 372px;
  }

  @media (min-width: 1280px) {
    height: 52vh;
  }
}

.filter-component-modal-css {
  width: 540px;

  @media (max-width: 576px) {
    min-width: 95vw !important;
    max-width: 95vw !important;
    height: 64vh !important;
  }
}

.pathshala-dialog {
  height: 95vh;
  width: 400px;
  border-radius: 20px;

  @media (max-width: 576px) {
    height: 80vh;
  }
}

/**
* Standard colors
*/

.pw-dark-blue {
  color: #5a4bda;
}

.duration-dialog-modal {
  height: 80vh;
  width: 40vw;
  border-radius: 15px !important;

  .mat-dialog-container {
    background: transparent !important;
    box-shadow: none;
  }

  @media (max-width: 576px) {
    width: 98vw;
    height: 90vh;
  }
}

.dialog-modal-backdrop {
  background-color: rgba(51, 51, 51, 0.719);
  backdrop-filter: blur(2px);
}

.payment-status-dialog {
  height: 60vh;
  width: 40vw;

  .mat-dialog-container {
    background: transparent !important;
    box-shadow: none;
  }

  @media (max-width: 576px) {
    width: 99vw;
    height: 60vh;
  }
}

.name-email-alert-css {
  .mat-dialog-container {
    padding: 16px !important;
    border-radius: 16px !important;

    @media (max-width: 540px) {
      padding: 10px;
      width: 95vw;
      height: 300px;
    }
  }
}

.teacher-detail-dialog {
  .mat-dialog-container {
    border-radius: 20px;
  }
}

.report-alert-css {
  border-radius: 25px;
  padding: 4px;

  .mat-dialog-container {
    max-height: 90vh;
  }
}

.edit-profile-dialog {
  width: 40vw;
  max-height: 98vh;

  .mat-dialog-container {
    border-radius: 20px;
    padding: 10px !important;
  }

  @media (max-width: 1024px) {
    width: 50vw;
  }

  @media (max-width: 700px) {
    width: 80vw;
  }

  @media (max-width: 576px) {
    width: 95vw;
    max-width: 95vw !important;
  }
}

.update-number-dialog {
  .mat-dialog-container {
    padding: 0 !important;
    border-radius: 0.5rem;
  }

  @media (max-width: 576px) {
    max-width: 95vw !important;
  }

  .update-number-modal-child {
    position: relative;

    .modal-title {
      color: #333333;
    }

    .component-container {
      max-height: 80vh;
      overflow-y: auto;
      max-width: 100%;
      width: 560px;

      .content-color {
        color: #3d3d3d;
      }

      .decline-btn {
        flex: 1;
        width: 100%;
        min-width: 200px;
        border-radius: 6px;
        padding: 13px 14px;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        border: 1px solid #d9dce1;
        color: #3d3d3d;
        background-color: #fff;
      }

      .decline-btn:hover {
        background-color: rgba(229, 231, 235, 0.4);
      }

      .accept-btn {
        flex: 1;
        width: 100%;
        min-width: 200px;
        border-radius: 6px;
        padding: 14px;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        background-color: #5a4bda;
        color: #fff;
      }

      .accept-btn:disabled {
        background-color: #d2ccff;
      }
    }
  }
}

.edit-profile-dialog,
.update-number-dialog {
  .close-icon {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    border-radius: 6px;
  }

  .close-icon:before,
  .close-icon:after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: ' ';
    height: 50%;
    width: 2px;
    background-color: #3d3d3d;
  }

  .close-icon:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .close-icon:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .close-icon:hover {
    background-color: rgba(229, 231, 235, 0.4);
  }
}

.today-class-model {
  .mat-dialog-container {
    width: 80vw;
    border-radius: 20px;
    height: auto;
    // max-width: 80vw;
    max-height: 90vh;

    @media (min-width: 768px) {
      width: 30vw;
    }
  }
}

.upgrade-dialog {
  .mat-dialog-container {
    width: 350px;
    border-radius: 15px;
  }
}

.faculty-dialog {
  border-radius: 20px;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;

  .mat-dialog-container {
    height: auto;
    max-height: 90vh;
    border-radius: 20px;
    padding: 10px !important;
    width: 95vw !important;

    @media (min-width: 576px) {
      width: 80vw !important;
    }

    @media (min-width: 600px) {
      width: 68vw !important;
    }

    @media (min-width: 768px) {
      width: 450px !important;
    }
  }
}

.faculty-dialog::-webkit-scrollbar {
  width: 0;
}

.attachment-dialog {
  width: 480px;
  height: auto;
  border-radius: 8px;
  background-color: #ffffff;

  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    min-height: 207px;
    padding: 20px;
  }
}

.menu-modal-component {
  .mat-dialog-container {
    background: transparent;
    box-shadow: none !important;
    padding: 0 !important;
    min-width: 200px;
    max-width: 90vw;

    @media (max-width: 768px) {
      min-width: 150px;
      z-index: 20;
    }
  }
}

.test-submit-dialog {
  height: 180px;
  border-radius: 20px;

  .mat-dialog-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 0px;
  }
}

.pause-dialog {
  min-height: 222px;
  border-radius: 20px;

  .mat-dialog-container {
    border-radius: 20px !important;
  }
}

.warning-dialog {
  min-height: 222px;
  max-width: 98vw !important;
  border-radius: 20px;

  @media (min-width: 768px) {
    max-width: 400px !important;
  }

  .mat-dialog-container {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 13px 68px #3d34661c !important;
    border-radius: 30px !important;
    padding: 0;
  }
}

.test-report-dialog {
  border-radius: 20px;

  .mat-dialog-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 0px;
    max-width: 40vw;

    @media (max-width: 576px) {
      max-width: 95vw;
    }
  }
}

.test-mobile-section-dialog {
  .mat-dialog-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 0px;
    max-width: 40vw;

    @media (max-width: 576px) {
      max-width: 95vw;
    }
  }
}

.resume-test-dialog {
  width: 300px;

  .mat-dialog-container {
    width: 98vw;
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    min-height: 107px !important;
    padding: 20px;
  }
}

.filter-dialog {
  .mat-dialog-container {
    border-radius: 20px !important;

    @media (max-width: 576px) {
      padding: 0;
      width: 96vw;
    }
  }
}

.info-card {
  border-radius: 8px;

  .mat-dialog-container {
    padding: 30px;
  }
}

.test-warning-dialog {
  max-width: 98vw !important;
  border-radius: 8px;

  @media (min-width: 768px) {
    max-width: 400px !important;
  }

  .mat-dialog-container {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    padding: 30px;
    border-radius: 8px !important;
  }
}

.test-start-dialog {
  max-height: 60vh !important;

  @media (max-width: 560px) {
    max-height: 80vh !important;
  }

  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    min-height: 207px !important;
    padding: 20px;
    max-width: 35vw;
    overflow: hidden;

    @media (max-width: 768px) {
      min-width: 85vw !important;
    }
  }

  .mat-dialog-container::-webkit-scrollbar {
    width: 0;
  }
}

.rating-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    min-height: 207px !important;
    max-height: 90vh;
    padding: 20px;
    width: 540px;
    overflow: hidden;

    @media (max-width: 576px) {
      min-width: 85vw !important;
    }

    @media (max-width: 400px) {
      padding: 0px;
    }
  }
}

.report-instruction-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    min-height: 400px !important;
    max-height: 90vh;
    padding: 20px;
    max-width: 540px;

    @media (max-width: 576px) {
      min-width: 85vw !important;
    }
  }
}

.report-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    min-height: 207px !important;
    max-height: 90vh;
    padding: 20px;
    max-width: 540px;

    @media (max-width: 576px) {
      min-width: 85vw !important;
    }
  }
}

.report-widget-dialog {
  .mat-dialog-container {
    height: 490px;
    padding: 0px;
  }
}

.registration-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    min-height: 207px !important;
    padding: 20px;
    max-width: 55vw;
    max-height: 95vh;

    @media (max-width: 576px) {
      min-width: 85vw !important;
    }
  }
}

.enroll-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    min-height: 207px !important;
    padding: 20px;
    max-width: 35vw;

    @media (max-width: 768px) {
      max-width: 52vw;
    }

    @media (max-width: 576px) {
      min-width: 85vw !important;
    }
  }
}

.batch-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    padding: 20px;
    width: 55vw;
    height: 55vh;

    @media (max-width: 768px) {
      width: 75vw;
      padding: 14px;
    }

    @media (max-width: 576px) {
      width: 97vw;
      padding: 6px;
    }
  }
}

.doubts-submit-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    padding: 20px;
    width: 300px;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.samsung-doubts-submit-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    padding: 20px;
    width: 400px;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.dpp-comment-delete-modal {
  .mat-dialog-container {
    width: 300px;
  }

  @media (max-width: 1025px) {
    width: 279px;
  }

  @media (max-width: 576px) {
    width: 300px;
  }
}

.dpp-comment-feedback-modal {
  .mat-dialog-container {
    width: 300px;
    padding: 5px;
    border-radius: 12px;

    @media (max-width: 1025px) {
      width: 250px;
    }

    @media (max-width: 576px) {
      width: 300px;
    }
  }
}

.privacy-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    //border-radius: 20px;
    width: 550px;
    height: 90vh;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.more-options-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    padding: 20px;
    width: 550px;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.exercise-solution-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    padding: 20px;
    width: 500px;
    height: 90vh;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.personal_assistant_dialog {
  .mat-dialog-container {
    border-radius: 10px;
    box-shadow: 0px 3px 20px #00000029;
    padding: 0px;
    width: 500px;
    height: 545px;
    background: #f1f3f6;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
      //max-width: 94vw;
    }
  }

  width: 500px !important;

  @media (max-width: 576px) {
    max-width: 94vw !important;
  }
}

.exercise-restart-test {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    padding: 20px;
    width: 480px;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.exercise-questions-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    padding: 20px;
    width: 550px;
    height: 90vh;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.leave-exercise-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 16px;
    padding: 24px 24px 14px 24px;
    width: 450px;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.teacher-rating-modal {
  border-radius: 10px !important;

  .mat-dialog-container {
    // height: auto;
    // max-height: 80vh;
    // overflow-y: scroll;
    // max-width: 90vh;
    width: 421px;
    // height: 495px;
    // padding: 10px !important;
    border-radius: 10px !important;
  }
}

.exercise-add-note-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    padding: 20px;
    width: 500px;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.notification-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    width: 500px;
    padding: 0;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.remove-bookmark-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    padding: 20px;
    width: 340px;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.course-rating-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    min-height: 207px !important;
    max-height: 90vh;
    padding: 20px;
    width: 540px;

    @media (max-width: 576px) {
      min-width: 85vw !important;
    }
  }
}

.course-report-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    min-height: 207px !important;
    max-height: 90vh;
    padding: 20px;
    max-width: 540px;

    @media (max-width: 576px) {
      min-width: 85vw !important;
    }
  }
}

.test-reattempt-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    padding: 10px;
    width: 240px;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.qbank-reattempt-dialog {
  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    padding: 8px;
    width: 330px;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.solution-list-dialog {
  width: 330px !important;
  height: 95vh !important;

  @media (max-width: 768px) {
    width: 75vw !important;
  }

  @media (max-width: 576px) {
    width: 97vw !important;
  }

  .mat-dialog-container {
    box-shadow: 0px 13px 36px #3443661c;
    border-radius: 20px;
    padding: 8px;
  }
}

.mobile-question-ui {
  max-width: 100vw !important;

  .mat-dialog-container {
    height: 90vh;
    overflow: scroll;

    @media (max-width: 821px) {
      width: 80vw;
    }

    @media (max-width: 560px) {
      width: 98vw;
      padding: 4px;
    }
  }
}

.search-dialog-component {
  height: 90vh;
  width: 80vw;

  @media (max-width: 992px) {
    min-width: 95vw;
  }

  @media (max-width: 576px) {
    max-height: 95vh;
  }

  .mat-dialog-container {
    border-radius: 20px;
  }
}

.enroll-status-dialog {
  width: fit-content;
  height: auto;

  @media (max-width: 576px) {
    min-width: 50vw;
  }

  .mat-dialog-container {
    border-radius: 15px;
    background: transparent;
    box-shadow: none;
  }

  @media (max-width: 576px) {
    min-width: 95vw !important;
  }

  .mat-button.mat-button-disabled {
    opacity: 0.6 !important;
  }
}

.add_new_goal {
  .mat-dialog-container {
    padding: 16px;
    box-shadow: 0px 3px 6px #00000029;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 423px;
  }

  @media (max-width: 576px) {
    max-width: 97vw !important;
  }
}

.plan-modal {
  .mat-dialog-container {
    padding: 16px;
    width: 577px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #00000029;
    border-radius: 15px;
  }

  @media (max-width: 576px) {
    max-width: 97vw !important;
  }
}

/* Classes for infinite scroller div */

.tests-scroller-div {
  height: calc(90vh - 80px);
  overflow-y: scroll;
  overflow-x: hidden;

  @media (min-width: 1500px) {
    height: 90.5vh;
  }

  @media (max-width: 1020px) {
    height: 88vh;
  }

  @media (max-width: 768px) {
    height: 90vh;
  }

  @media (max-width: 665px) {
    height: 90vh;
  }

  @media (max-width: 576px) {
    height: 92vh;
  }

  @media (max-width: 400px) {
    height: 90vh;
  }
}

.app-scroller-div {
  height: calc(90vh - 90px);
  overflow-y: scroll;

  @media (min-width: 1500px) {
    height: 90.5vh;
  }

  @media (max-width: 992px) {
    height: calc(62vh - 85px);
  }

  @media (max-width: 768px) {
    height: calc(76vh - 195px);
  }

  @media (max-width: 665px) {
    height: calc(76vh - 245px);
  }

  @media (max-width: 576px) {
    height: calc(84vh - 296px);
  }

  @media (max-width: 400px) {
    height: calc(85vh - 165px);
  }
}

.in-app-message-css {
  border-radius: 30px !important;

  .mat-dialog-container {
    max-height: 80vh;
    border-radius: 30px !important;
  }
}

#rs_penpencil_player {
  .vjs-liveui {
    .vjs-liveui-display {
      display: none;
    }
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.announcement-image-div {
  height: auto;
  max-height: 95vh;
  max-width: 80vw;
}

// Dropdown Exam list style
// .container {
//   max-width: 100%;
//   margin: 0 auto;
// }
// .select-box select {
// }
// .select-box {
//   width: 169px;
// height: 40px;
// background: #F5F3FF 0% 0% no-repeat padding-box;
// border-radius: 6px;
// opacity: 1;
// }
.mat-form-field-wrapper {
  margin-left: 20px;
}

.drop-down-web {
  .mat-form-field-wrapper {
    margin-left: 0px;
  }
}

.mat-toolbar .mat-select-value {
  font: normal normal bold 14px/26px;
  color: #000000;
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: translateY(-0%) !important;
}

.mat-form-field-appeara nce-fill .mat-form-field-flex {
  // background-color: rgba(0, 0, 0, 0.04);
  background: #f5f3ff 0% 0% no-repeat padding-box;
}

.mat-form-field-label {
  padding-top: 5px !important;
}

.vote-modal {
  .mat-dialog-container {
    width: auto;
    height: auto;
    padding: 10px 5px 10px 5px !important;
    transition: width height linear 0.5s !important;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.vjs-setting-title,
.vjs-setting-speed {
  @media (max-width: 576px) {
    font-size: 10px !important;
    z-index: 20 !important;
    padding: 4px 0 !important;
  }
}

// Class to remove double radio button in player starts
.vjs-setting-item.vjs-icon-circle-outline::before {
  display: none !important;
}

.vjs-setting-item.vjs-icon-circle-inner-circle::before {
  display: none !important;
}

.vjs-setting-item.vjs-icon-play::before {
  display: none !important;
}

// Class to remove double radio button in player ends

.vjs-setting-item {
  @media (max-width: 768px) {
    padding: 0 !important;
    z-index: 20 !important;
  }
}

.enrollook-modal {
  .mat-dialog-container {
    padding: 18px;
    min-width: 60vh;
    border-radius: 12px;
    max-height: 90vh !important;

    width: 80vh;
  }
}

.image-dialog-modal {
  border-radius: 10px !important;
  // height: fit-content;

  .mat-dialog-container {
    padding: 0px;
    border-radius: 10px !important;
  }
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.upgrade-to-saarthi-modal {
  .mat-dialog-container {
    padding: 10px !important;
  }
}

.vjs-button {
  color: #5a4bda !important;
}

.filter-new-modal {
  height: 100%;

  .mat-dialog-container {
    padding: 0 !important;
    border-radius: 16px 0px 0px 16px !important;
    box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.1) !important;
    // overflow: inherit;
    // height: 100vh !important;
  }
}

.download-pdf-modal {
  .mat-dialog-container {
    padding: 10px !important;
    max-height: 80vh;
    width: 42vw;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 20px;

    @media (max-width: 768px) {
      width: 80vw;
    }
  }
}

.image-sol-modal {
  .mat-dialog-container {
    max-height: 80vh;
    // max-width: 80vw;
  }
}

.lead-filter-slider {
  .mat-dialog-container {
    width: 380px;
    padding: 0px;
  }
}

.installment-details {
  .mat-dialog-container {
    padding: 24px;
    width: 480px;
    border-radius: 8px;
  }
  @media (max-width: 640px) {
    width: 200px;
  }
}

.vjs-button {
  @media (max-width: 576px) {
    font-size: 1.5rem;
  }
}

.vjs-current-time-display,
.vjs-duration-display {
  @media (max-width: 576px) {
    font-size: 16px;
  }
}

.vjs-current-time-display {
  @media (max-width: 576px) {
    left: -94px !important;
  }
}

.vjs-progress-control {
  @media (max-width: 576px) {
    font-size: 1.5rem;
  }
}

.error {
  .ngx-otp-input-box {
    top: 205px;
    left: 875px;
    width: 37px;
    height: 37px;

    box-shadow: 0px 3px 10px #182e452e;
    border: 1px solid #ff0f48;
    border-radius: 6px;
    opacity: 1;
  }
}

.success {
  .ngx-otp-input-box {
    top: 205px;
    left: 875px;
    width: 37px;
    height: 37px;

    box-shadow: 0px 3px 10px #182e452e;
    border: 1px solid green;
    border-radius: 6px;
    opacity: 1;
  }
}

.type_question_here {
  border-radius: 12px !important;

  .mat-dialog-container {
    max-height: 80vh;
    padding: 0px;
    border-radius: 12px !important;
  }
}

.filter-day-modal {
  .mat-dialog-container {
    padding: 0 !important;
    box-shadow: 0px -3px 15px rgba(0, 0, 0, 0.04),
      0px 5px 4px rgba(0, 0, 0, 0.01), 0px 25px 35px rgba(0, 0, 0, 0.03) !important;
  }
}

.study-filter-modal {
  .mat-dialog-container {
    max-height: 98vh;
    overflow-y: scroll;
    padding: 0 !important;
    box-shadow: 0px -3px 15px rgba(0, 0, 0, 0.04),
      0px 5px 4px rgba(0, 0, 0, 0.01), 0px 25px 35px rgba(0, 0, 0, 0.03) !important;
  }
}

.sme-detail-modal {
  .mat-dialog-container {
    max-width: 300px !important;
    // max-height: 45vh;
    border-radius: 12px;
  }
}

.doubt-reply-feedback-modal {
  .mat-dialog-container {
    // max-width: 300px !important;
    border-radius: 12px;
    width: 340px;
    max-height: 90vh;
    padding: 8px !important;
  }
}

.doubt-solver-modal {
  .mat-dialog-container {
    width: 370px !important;
    max-height: 50vh;
    border-radius: 12px;
    padding: 0px !important;

    @media (max-width: 576px) {
      width: 328px !important;
    }
  }
}

.voucher-detail-dialog {
  border-radius: 10px !important;

  .mat-dialog-container {
    padding: 0px 20px 0px 20px;
    border-radius: 10px !important;
    height: 535px;
    max-height: 80vh;
    // width: 400px;
    // @media (max-width: 576px) {
    //   width: 95vw !important;
    // }
  }
}

.referral-list-modal {
  .mat-dialog-container {
    width: 100%;
    min-width: 550px;
    max-height: 90vh;
    padding: 0;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #00000029;
    border-radius: 15px;

    @media (max-width: 640px) {
      min-width: 90vw;
    }
  }

  @media (max-width: 576px) {
    max-width: 97vw !important;
  }
}

.transaction-history-dialog {
  .mat-dialog-container {
    max-height: 90vh;
    padding: 0;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #00000029;
    border-radius: 15px;
  }

  @media (max-width: 576px) {
    max-width: 97vw !important;
  }

  // max-height: 60vh;
  // max-width: 40vw;
}

.transaction-status-dialog {
  border-radius: 10px !important;

  .mat-dialog-container {
    padding: 0px 20px 0px 20px;
    border-radius: 10px !important;
    max-height: 80vh;
    width: 365px;
    // @media (max-height: 576px) {
    //   height: 95vh !important;
    // }
  }
}

.bank-dialog-modal {
  border-radius: 10px !important;

  .mat-dialog-container {
    padding: 0px;
    border-radius: 10px !important;
  }
}

.offers-modal {
  width: 450px;
  height: 450px;

  @media (max-width: 480px) {
    //width:360px;
    height: 320px;
  }

  //background: transparent !important;
  .mat-dialog-container {
    padding: 0px;
    border-radius: 14px !important;
    background: transparent !important;
    box-shadow: none !important;
    //background: transparent !important;
  }
}

.scientific-calc {
  .mat-dialog-container {
    padding: 0px !important;
    background: transparent;
    box-shadow: none;
  }
}

.schedule-dialog {
  height: 100vh;
  width: 480px;

  .mat-dialog-container {
    border-radius: 0px;
    padding: 0 !important;

    @media (width < 576px) {
      padding: 12px;
    }
  }

  @media (width < 576px) {
    max-width: 100vw !important;
  }
}

.cohort-dialog {
  height: 100vh;
  width: 560px;

  .mat-dialog-container {
    border-radius: 16px 0 0 16px !important;

    @media (width < 576px) {
      padding: 12px !important;
      border-radius: 0 !important;
    }
  }

  @media (width < 576px) {
    max-width: 100vw !important;
  }
}

.test-sidebar {
  height: 100%;
  width: 480px;
  .mat-dialog-container {
    padding: 0 !important;
    border-radius: 0px !important;
    box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.1) !important;
    .content-link a {
      color: #037cbf !important;
      text-decoration: underline !important;
      font-weight: 500;
    }
  }
}

.mahapack-side-dialog {
  height: 100vh;
  width: 452px;

  .mat-dialog-container {
    border-radius: 8px 0 0 8px !important;
    padding: 0 !important;

    @media (width < 576px) {
      border-radius: 0 !important;
    }
  }

  @media (width < 576px) {
    max-width: 100vw !important;
  }
}

.cohort-exam-dialog {
  width: 430px;
  height: 470px;

  @media (width < 576px) {
    max-width: 90vw !important;
  }

  .mat-dialog-container {
    border-radius: 16px !important;
    box-shadow: rgba(0, 0, 0, 0.1);
  }
}

.view-solution-modal {
  .mat-dialog-container {
    width: 600px;
    height: 472px;
    padding: 24px 0px;
  }
}

.exam-dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  // opacity: 0.4 !important;
}

.loading-page-dialog {
  max-width: 100vw !important;
  height: 100vh;
  width: 100vw;

  .mat-dialog-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 !important;
  }
}

.cohort-icons {
  height: 50px !important;
  width: 50px !important;
}

.scientific-calc {
  .mat-dialog-container {
    padding: 0px !important;
    background: transparent;
    box-shadow: none;
  }
}

.play-pause-modal-component {
  .mat-dialog-container {
    background: transparent;
    box-shadow: none !important;
  }
}

.doubt-solution-modal {
  .mat-dialog-container {
    padding: 0px !important;
    background: transparent;
    box-shadow: none;
    max-width: 330px;
  }
}

.doubt-sidebar {
  height: 100vh !important;
  width: 450px !important;

  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar {
    width: 0px !important;
  }

  .mat-dialog-container {
    border-radius: 12px 0 0 12px !important;
    padding: 0px !important;

    @media (width < 576px) {
      border-radius: 0 !important;
    }
  }

  @media (width < 576px) {
    max-width: 100vw !important;
  }
}

.samsung-doubt-sidebar {
  height: 100vh !important;
  width: 675px !important;

  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar {
    width: 0px !important;
  }

  .mat-dialog-container {
    border-radius: 12px 0 0 12px !important;
    padding: 0px !important;

    @media (width < 576px) {
      border-radius: 0 !important;
    }
  }

  @media (width < 576px) {
    max-width: 100vw !important;
  }
}

// class to remove double time stamp
.vjs-mouse-display {
  .vjs-time-tooltip {
    display: none !important;
  }
}

.warning-modal {
  max-width: 80vh;

  @media (width>600px) {
    width: 400px;
  }

  .mat-dialog-container {
    border-radius: 8px;
  }
}

.reset-optional-section {
  .mat-dialog-container {
    border-radius: 8px;
  }
}

.video-player-modal {
  height: 40vh;
  width: 50vw;

  .mat-dialog-container {
    border-radius: 8px !important;
    padding: 0 !important;

    @media (width < 576px) {
      border-radius: 0 !important;
    }
  }
}

.video-player-modal2 {
  aspect-ratio: 16/9;
  width: 59vw;

  .mat-dialog-container {
    border-radius: 8px !important;
    padding: 0 !important;

    @media (width < 576px) {
      border-radius: 0 !important;
    }
  }

  @media (width < 576px) {
    max-width: 95vw !important;
    width: 100%;
  }
}

.teacher-dialog {
  width: 30vw;

  .mat-dialog-container {
    border-radius: 8px !important;
    padding: 0 !important;

    @media (width < 576px) {
      border-radius: 0 !important;
    }
  }

  @media (width < 576px) {
    max-width: 95vw !important;
    width: 95vw;
  }
}

.admit-card-modal {
  overflow: hidden;
  border-radius: 12px;

  .mat-dialog-container {
    padding: 0px 5px 12px 5px !important;
    width: 560px;
    min-height: 300px;
    max-height: 650px;
    overflow-y: auto;
    border-radius: 12px;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (width< 560px) {
      max-height: 500px;
    }
  }

  @media (width< 560px) {
    max-width: 98vw !important;
  }
}

.expiry-popup-modal {
  overflow: hidden;
  border-radius: 16px;

  .mat-dialog-container {
    padding: 32px 16px !important;
    width: 560px;
    min-height: 330px;
    max-height: 650px;
    overflow-y: auto;
    border-radius: 12px;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (width< 560px) {
      max-height: 500px;
    }
  }

  @media (width< 560px) {
    max-width: 98vw !important;
  }
}

.admit-card-success-modal {
  .mat-dialog-container {
    padding: 5px !important;
    width: 500px;
    border-radius: 12px;

    @media (max-width: 768px) {
      width: 100vw;
    }
  }
}
.streak-modal {
  .mat-dialog-container {
    padding: 0px !important;
    width: 480px;
    height: auto !important;
    border-radius: 8px;

    @media (max-width: 768px) {
      width: 100vw;
    }
  }
}

.description-meta-container {
  display: flex !important;
  flex-direction: column !important;
  gap: 14px !important;

  .child-container {
    display: flex !important;
    align-items: center !important;
    gap: 12px !important;

    .icon {
      width: 32px !important;
      height: 32px !important;
    }

    .sub-text {
      font-size: 14px !important;
      font-weight: 500 !important;
      color: #888888 !important;
    }

    .main-text {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: #1b2124 !important;
    }

    .text {
      font-size: 14px !important;
      font-weight: 500 !important;
      color: #1b2124 !important;
    }

    .text-container {
      display: flex !important;
      flex-direction: column !important;
      gap: 4px !important;
    }
  }
}

.teacher-intro-container {
  display: flex !important;
  flex-direction: column !important;
  gap: 6px !important;

  .teacher-intro {
    display: flex !important;
    align-items: start !important;
    gap: 8px !important;
  }

  .bullets {
    height: 7px !important;
    width: 7px !important;
    margin-top: 5px !important;
  }

  .intro-text {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

.coach {
  border-radius: 16px !important;
  max-height: 372px;

  .mat-dialog-container {
    padding: 16px !important;
    border-radius: 16px !important;
  }
}

.break {
  border-radius: 16px !important;

  .mat-dialog-container {
    padding: 16px !important;
    border-radius: 16px !important;
  }
}

.notification {
  border-radius: 16px !important;
  max-height: 250px;

  .mat-dialog-container {
    padding: 8px !important;
    border-radius: 16px !important;
  }
}

.find-modal {
  max-height: 400px;

  .mat-dialog-container {
    padding: 0px;
    max-height: 400px;
    border-radius: 16px !important;
  }
}

.image-success {
  max-height: 300px;
  border-radius: 16px !important;

  .mat-dialog-container {
    padding: 20px;
    border-radius: 16px !important;
  }
}

.no-coach {
  max-height: 370px;
  border-radius: 16px !important;

  .mat-dialog-container {
    padding: 12px;
    border-radius: 16px !important;
  }
}

.image-upload {
  max-height: 362px;
  border-radius: 16px !important;

  .mat-dialog-container {
    padding: 16px;
    border-radius: 16px !important;
  }
}

.saarthi-video-modal {
  .mat-dialog-container {
    // padding: 16px;
  }
}

.vidyapeeth-color {
  color: #1b2124;
}

.widget-wrapper {
  background-color: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: 768px) {
    padding-left: 14px;
    padding-right: 14px;
  }
}

.scholarship-term-condition-modal {
  .mat-dialog-container {
    width: 544px;
    max-height: 70vh;
    border-radius: 8px;
    padding: 0;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.leave-form-modal {
  .mat-dialog-container {
    width: 450px;
    max-height: 70vh;
    border-radius: 8px;
    padding: 0;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.form-not-generated-modal {
  .mat-dialog-container {
    width: 480px;
    max-height: 70vh;
    border-radius: 8px;
    padding: 0;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 576px) {
      width: 97vw;
    }
  }
}

.vidyapeeth-color {
  color: #1b2124;
}

.hamburger-info-dialog {
  height: 100vh;
  width: 240px;

  .mat-dialog-container {
    border-radius: 16px 0 0 16px !important;
    background: transparent;
    box-shadow: none;

    @media (width < 576px) {
      padding: 12px !important;
      border-radius: 0 !important;
    }
  }

  @media (width < 576px) {
    max-width: 100vw !important;
  }
}

.batch-plus-dialog {
  height: 605px;
  background: #000000;
  border-radius: 8px;
  max-width: 100% !important;
  .mat-dialog-container {
    width: 100%;
    background: #000000;
    padding: 0;
    border-radius: 8px;
    overflow: hidden;
  }

  @media screen and (min-width: 280px) and (max-width: 425px) {
    height: 605px;
  }

  @media screen and (min-width: 425px) and (max-width: 768px) {
    height: 605px;
  }

  @media screen and (min-width: 789px) and (max-width: 1440px) {
    height: 605px;
  }
}

.premium-book-session {
  height: 100vh;
  width: 480px;

  .mat-dialog-container {
    position: relative;
    padding: 0px;
  }

  @media (width < 576px) {
    max-width: 100vw !important;
  }
}
.fee-breakup {
  height: 100vh;
  width: 452px;
  .mat-dialog-container {
    position: relative;
    padding: 0px;
    border-radius: 16px 0px 0px 16px;
    background: #fff;
    box-shadow: 0px 12px 36px 0px rgba(0, 0, 0, 0.1);
  }

  @media (width < 576px) {
    max-width: 100vw !important;
  }
}

.contact-us-dialog {
  width: 480px;
  .mat-dialog-container {
    padding: 24px;
    border-radius: 8px;
    overflow: hidden;

    @media (width < 576px) {
      padding: 14px;
    }
  }
  @media (width < 576px) {
    max-width: 95vw !important;
    width: 95vw;
  }
}

.batch-plus-upgrade {
  width: 480px;
  max-height: 90vh;
  background: transparent;
  .mat-dialog-container {
    padding: 0 !important;
    border-radius: 8px;
  }
  @media (width < 576px) {
    max-width: 95vw !important;
    width: 95vw;
  }
}

.session-modal {
  width: 450px;
  .mat-dialog-container {
    border-radius: 8px !important;
  }
}

.subjective-loading-screen {
  max-width: 100vw !important;
  height: 100vh;
  width: 100vw;

  .mat-dialog-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 75%);
  }

  100% {
    background-color: hsl(200, 20%, 90%);
  }
}

.snooze-dialog {
  .mat-dialog-container {
    padding: 0 !important;
  }
}
.custom-optgroup-label {
  font-size: 0.75rem;
}

.batch-info-modal {
  width: 440px;
  .mat-dialog-container {
    padding: 0 !important;
    border-radius: 8px;
  }
  @media (width < 576px) {
    max-width: 95vw !important;
    width: 95vw;
  }
}

.ai-guru-info-modal-panel {
  .mat-dialog-container {
    overflow: visible;
    position: relative;
    border-radius: 20px;
  }
}
.ai-guru-reminder-modal-panel {
  .mat-dialog-container {
    border-radius: 20px;
  }
}
